@import '../functions/rem-fn'
@import '../mixins/media'
@import '~quasar/src/css/variables.sass'
@import '../bs-design.variables'

@mixin social-button
  border-color: $bs-light-grey-outline
  border-width: rem-fn(1)
  color: #000
  font-weight: normal
  text-transform: none

  .q-btn__wrapper
    padding-block: rem-fn(12)
    padding-inline: rem-fn(18)

  .q-btn__content
    justify-content: flex-start

  .q-icon
    margin-inline-end: rem-fn(25)

  @include from($breakpoint-md-min)
    max-inline-size: rem-fn(370)

    .q-btn__wrapper
      padding: rem-fn(30)
