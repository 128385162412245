//
//	@usage: Pass a size (in PX) to get the gap
//	@param: {String} $size
//	@param: {String} $direction
//	@param: {Boolean} $clearPreviousDirection
//

@use '../functions/rem-fn' as *

@mixin gap($size, $direction: 'column', $clearPreviousDirection: false)
  @if ($direction == 'column')
    column-gap: rem-fn($size)
  @else if($direction == 'row')
    row-gap: rem-fn($size)
  @else
    @error 'Gap mixin: unexpected parameter $direction: #{$direction}'

  @supports not (gap: 1px)
    @if ($direction == 'column')
      & > * + *
        margin-inline-start: rem-fn($size)

        @if ($clearPreviousDirection)
          margin-block-start: 0
    @else if($direction == 'row')
      & > * + *
        margin-block-start: rem-fn($size)

        @if ($clearPreviousDirection)
          margin-inline-start: 0
    @else
      @error 'Gap mixin: unexpected parameter $direction: #{$direction}'
