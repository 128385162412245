@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import '~booksprout-app/src/css/functions/rem-fn'
@import '~booksprout-app/src/css/mixins/flex-gap'
@import '~booksprout-app/src/css/mixins/social-button'
@import '~booksprout-app/src/css/mixins/media'

.bs-social-login-buttons
  @include gap(10, 'row')
  display: flex
  flex-flow: column

  @include from($breakpoint-md-min)
    @include gap(30, 'row')

.bs-facebook,
.bs-amazon
  @include social-button

// these rules are strict, DO NOT CHANGE
.bs-apple
  @include social-button
  background-color: #fff !important
  border-color: #000

  .q-btn__wrapper
    padding-block: rem-fn(4)
    padding-inline: rem-fn(12)

    @include from($breakpoint-md-min)
      padding-block: rem-fn(17)

  .q-icon
    margin-inline-end: rem-fn(15)

  &:hover,
  &:focus
    .q-focus-helper
      background: white !important
      opacity: 1 !important

      &:before
        opacity: 0 !important

      &:after
        opacity: 1 !important

.bs-email
  @include social-button
